import React from 'react';
import { Button } from 'primereact/button';
import Timelines from './TimeLines';


export default function FrontPage() {
  return (
    <div>
        <p>
            未完待续。。。
        </p>
        <div>
            <Timelines />
        </div>
    </div>
  );
}