import React from 'react'; 
import { Timeline } from 'primereact/timeline';
import { Card } from 'primereact/card';
import './TimeLines.css'

export default function TimeLines() {
    const events = [];

    events.push({date: '2024-11-15', title: '\"异国恋\"', day: 'Friday', weather: 'Rainy', location: 'Auckland', 
        text: '人生中有太多的第一次，三周的时间不长也不短，let\'s go!!', icon: 'pi pi-apple', color: '#75c6d9'});

    events.push({date: '2024-11-14', title: '\"Plan\"', day: 'Thursday', weather: 'Rainy', location: 'Gganbu', 
        text: '今天丫头倾诉了对待我们进度的迷茫，希望有这么一个plan；没问题，你一直做的很好，我也会做好\"领路人\"，master我们共同努力！', icon: 'pi pi-heart-fill', color: 'red'});    
    
    events.push({date: '2024-11-09', title: '\"跃然纸上\"', day: 'Saturday', weather: 'Cloudy', location: 'La Spiaggia and Orewa', 
        text: '美妙的同频，我们同时提出想通过思考方式和实际行为来更深层次的了解对方；我们的相处真是很健康的relationship，舒适甜蜜且有规划，感性和理性交织融合，谢谢你！', icon: 'pi pi-sun', color: '#75c6d9'});

    events.push({date: '2024-10-19', title: '\"两小时\"', day: 'Saturday', weather: 'Sunny', location: 'Waiheke', 
        text: 'Waiheke，攻略有些偏差，本来6h的徒步，我和丫头说2h左右，导致你一路飞奔加小跑，最后路牌破案。你一直这么勇敢有耐心且不服输，有时候掺杂着这么一丢丢童真，我可不要欺负你。', icon: 'pi pi-thumbs-up-fill', color: '#0b669b'});

    events.push({date: '2024-10-15', title: '\"相互体贴\"', day: 'Tuesday', weather: 'Cloudy', location: 'On the bus', 
        text: '我想去Rangi，你提了Waiheke；结果是我在做Waiheke的攻略而你在看Rangi的线路。这真是个美妙的感觉。', icon: 'pi pi-check', color: '#f4a65e'});

    events.push({date: '2024-10-11', title: '\"纪念日\"', day: 'Friday', weather: 'Sunny', location: 'Mission bay-Torbay', 
        text: 'You said yes', icon: 'pi pi-heart-fill', color: 'red'});

    events.push({date: '2024-10-09', title: '\"法式苹果派\"', day: 'Wednesday', weather: 'Rainy', location: 'Esther', 
        text: '暴躁大姐，强制点单；你挥着拳头要argue；我表达了要跟进一步且在一起的意愿，你说你会答复我；我表达了最真实的想法。', icon: 'pi pi-hammer', color: '#9C27B0'});
    
    events.push({date: '2024-10-06', title: '\"九\"', day: 'Sunday', weather: 'Rainy', location: 'Cypress', 
        text: '广东菜，两个人越来越敞开心扉，天南海北，无所不聊，主题跳跃性极强；我慢慢习惯了有你的存在，有你的问候，有你的鼓励。', icon: 'pi pi-crown', color: '#9C27B0'});

    events.push({date: '2024-10-05', title: 'Coolant Bypass Valve', day: 'Saturday', weather: 'Rainy', location: 'Whangarei', 
        text: '你决定冒雨登山，我很惊讶也很欣喜；俩人各种抄近路钻地洞，欢乐满满；车抛锚了，我有些担心你，你却很镇定地排查和安慰我；王同学不畏惧困难，和你在一起，我总是能获得满满的正能量。', icon: 'pi pi-cog', color: '#673AB7'});

    events.push({date: '2024-09-28', title: '\"小猴子\"', day: 'Saturday', weather: 'Sunny', location: 'Tree Adventure', 
        text: '爬树！你一路披荆斩棘，过关斩将，无所畏惧直面zipline；你一直乐观有毅力，一直走在挑战自己的路上。', icon: 'pi pi-sparkles', color: '#FF9800'});

    events.push({date: '2024-09-26', title: '\"优化流程\"', day: 'Thursday', weather: 'Sunny', location: 'Online', 
        text: '早上和我分享你优化了起床流程，这真是个奇妙的分享，你采纳了我的建议且付诸行动，当时心底涌上一种从未有过的暖意。', icon: 'pi pi-lightbulb', color: '#607D8B'});

    events.push({date: '2024-09-22', title: '\"放松\"', day: 'Sunday', weather: 'Sunny', location: 'Omanawanui Track', 
        text: '你毫不费力的完成了徒手攀爬铁链子的两段路；一路照顾我，也觉察出了我下山时有点累了；你乐观积极，理性冷静，和你在一起我觉得十分放松和舒适。', icon: 'pi pi-star', color: '#9C27B0'});

    events.push({date: '2024-09-21', title: '\"阳伞\"', day: 'Saturday', weather: 'Sunny', location: 'Devonport and Mairangi Bay', 
        text: '俩人都给猫咖的捣蛋猫挠了；不过在Mairangi Bay \"赶海\" 乐趣多多；天南海北畅聊一通，相约一起完成两天一夜的徒步，挑战不洗澡的酸爽；王同学有自己的原则和底线，但是也非常愿意尝试新鲜事物，你有想法有冲劲。', icon: 'pi pi-sun', color: '#673AB7'});

    events.push({date: '2024-09-19', title: '\"镜像\"', day: 'Thursday', weather: 'Cloudy', location: 'Siso', 
        text: '安静的餐厅和昏暗的光线，氛围感十足；从感情经历到生活中的细枝末节，两人彷佛照镜子一般，依然不太相信可以在三观上遇到如此合拍的人，若故人重逢。王同学聪明善良有条理，生活井然有序。', icon: 'pi pi-images', color: '#FF9800'});

    events.push({date: '2024-09-17', title: '\"中秋节\"', day: 'Tuesday', weather: 'Cloudy', location: 'Sky Tower', 
        text: '我们互送了礼物，非常喜欢你送的蛋挞，香甜酥脆；还好我选的月饼正合你口味，让我觉得送礼物这件事更加有意义了，能一起分享节日的温暖真的很特别。', icon: 'pi pi-gift', color: '#607D8B'});

    events.push({date: '2024-09-15', title: '\"月下枕星河\"', day: 'Sunday', weather: 'Windy', location: 'Potters Park', 
        text: '雨后的冷风也没有影响你的表演，曼妙的舞姿让人无法相信你只是初学者；知道我不太能吃辣，你很贴心的要了微辣；深度了解人生观和择偶观，俩人愉快的决定从\"好朋友\"做起。', icon: 'pi pi-thumbs-up', color: '#9C27B0'});

    events.push({date: '2024-09-14', title: '\"阴差阳错\"', day: 'Saturday', weather: 'Rainy', location: 'Mount Eden War Memorial Hall', 
        text: '因为下雨没赶上演出，不过坚持不懈误打误撞的找到了你；在金橘满满谈论了各自的生活细节也畅谈了人生目标。你严谨自律，对生活有规划，对自己负责任。', icon: 'pi pi-exclamation-triangle', color: '#673AB7'});

    events.push({date: '2024-09-07', title: '\"慢炖羊肉\"', day: 'Saturday', weather: 'Cloudy', location: 'Bodrum', 
        text: '这一周很想和你多聊聊，有些担心吃饭时会冷场；不过我的担心是多余的，细心的跟我share了食物；互相交换了生活中的爱好，对于国家管控新冠的态度也高度一致。你睿智机敏，喜欢读书且善于思考。', icon: 'pi pi-refresh', color: '#FF9800'});

    events.push({date: '2024-08-31', title: '\"谁是卧底\"', day: 'Saturday', weather: 'Sunny', location: 'Onehunga Library', 
        text: '第一次和你见面，个子很高；游戏中理解学习的很快，组织能力也强；贴心的做了一次法官让我也体验了游戏。', icon: 'pi pi-trophy', color: '#607D8B'});

    const getSubTitle = (item) => {
        return `${item.day} - ${item.weather} - ${item.location}`;
    }

    const customizedMarker = (item) => {
        return (
            <span className="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1" style={{ backgroundColor: item.color }}>
                <i className={item.icon}></i>
            </span>
        );
    };

    const customizedContent = (item) => {
        return (
            <div>
                <Card title={item.title} subTitle={getSubTitle(item)}>
                    <p>{item.text}</p>
                </Card>
            </div>
        );
    };
        
    return (
        <div className="card">
            <Timeline 
                value={events} 
                align="alternate" 
                style={{width: '700px'}}
                opposite={(item) => item.date} 
                className="customized-timeline-item" 
                marker={customizedMarker} 
                content={customizedContent} />
        </div>
    )
}