import logo from './logo.svg';
import './App.css';
import FrontPage from './Components/FrontPage';

import 'primeflex/primeflex.css';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primeicons/primeicons.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
            Only for Amanda Wang
        </p>
        <FrontPage></FrontPage>
      </header>
    </div>
  );
}

export default App;
